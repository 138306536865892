import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Modal,Spinner } from "react-bootstrap";

let zIndexCounter = 1000;

function UserDashboard() {
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalIframeSrc, setModalIframeSrc] = useState("");
  const [draggedCardIndex, setDraggedCardIndex] = useState(null);
  const [cardZIndices, setCardZIndices] = useState({});
  const [cardSizes, setCardSizes] = useState({});

  const token = sessionStorage.getItem("token");
  const containerRef = useRef(null);
  const observerRef = useRef();

  useEffect(() => {
    fetchLikedDocuments(currentPage);
  }, [currentPage]);

  const fetchLikedDocuments = (page) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/likedDocuments`, {
        params: { page, limit: 10 },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDocuments((prevDocs) => [...prevDocs, ...response.data.likedDocuments]);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      })
      .catch(() => {
        toast.error("No Liked Documents Found");
      
      })
      .finally(() => {
        setLoading(false); // Hide loading spinner
      })
      ;
  };

  const toggleBookmark = async (index) => {
    const document = documents[index];
    const { id: documentId } = document;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/bookmarkDocument`,
        { documentId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const updatedDocuments = [...documents];
      updatedDocuments[index] = {
        ...document,
        saved: response.data.saved,
      };
      setDocuments(updatedDocuments);
    } catch (error) {
      console.error("Error bookmarking document:", error);
    }
  };

  const lastDocumentElementRef = (node) => {
    if (loading) return;
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observerRef.current.observe(node);
  };

  const handleFullscreenClick = (doc) => {
    setModalIframeSrc(doc?.docs[0]);
    setModalTitle(doc.title);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDragStart = (e, index) => {
    if (e.target.className.includes("resize-handle")) return;

    e.preventDefault();
    setDraggedCardIndex(index);
    const cardElement = e.currentTarget;
    const iframe = cardElement.querySelector("iframe");

    iframe.style.pointerEvents = "none";

    const { left, top } = cardElement.getBoundingClientRect();
    const offsetX = e.clientX - left;
    const offsetY = e.clientY - top;

    setCardZIndices((prev) => ({
      ...prev,
      [index]: ++zIndexCounter,
    }));

    const handleMouseMove = (moveEvent) => {
      const containerBounds = containerRef.current.getBoundingClientRect();
      let newLeft = moveEvent.clientX - offsetX;
      let newTop = moveEvent.clientY - offsetY;

      newLeft = Math.max(0, Math.min(newLeft, containerBounds.width - cardElement.offsetWidth));
      newTop = Math.max(0, Math.min(newTop, containerBounds.height - cardElement.offsetHeight));

      cardElement.style.position = "absolute";
      cardElement.style.left = `${newLeft}px`;
      cardElement.style.top = `${newTop}px`;
    };

    const handleMouseUp = () => {
      iframe.style.pointerEvents = "auto";
      setDraggedCardIndex(null);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleResize = (e, index, direction) => {
    e.preventDefault();

    const cardElement = e.currentTarget.parentElement;
    const initialWidth = cardElement.offsetWidth;
    const initialHeight = cardElement.offsetHeight;
    const startX = e.clientX;
    const startY = e.clientY;

    const handleMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;

      let newWidth = initialWidth + (direction.includes("right") ? deltaX : -deltaX);
      let newHeight = initialHeight + (direction.includes("bottom") ? deltaY : -deltaY);

      // Minimum width and height constraints
      newWidth = Math.max(400, newWidth); // Minimum width of 400px
      newHeight = Math.max(400, newHeight); // Minimum height of 400px

      // Set new size only if it doesn't exceed the container's dimensions
      const containerBounds = containerRef.current.getBoundingClientRect();
      if (
        newWidth <= containerBounds.width && 
        newHeight <= containerBounds.height
      ) {
        setCardSizes((prev) => ({
          ...prev,
          [index]: { width: newWidth, height: newHeight },
        }));
      }
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <div
      ref={containerRef}
      style={{ position: "relative", minHeight: "100vh", overflow: "auto" }}
    >
       {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <Spinner animation="border" variant="primary" /> {/* Loader */}
        </div>
      ):<> <div className="row" style={{ position: "relative" }}>
      {documents.length > 0 ? (
        documents.map((doc, index) => (
          <div
            className="col-xl-6 col-lg-6 col-md-12 mb-4"
            key={index}
            onMouseDown={(e) => handleDragStart(e, index)}
            style={{ position: "relative" }}
          >
            <Card
              className="card mb-3"
              style={{
                position: "relative",
                zIndex: cardZIndices[index] || 1,
                width: cardSizes[index]?.width || "480px",
                height: cardSizes[index]?.height || "480px",
              }}
            >
              <CardBody style={{ position: "relative", height: "100%" }}>
                <div className="d-flex fw-bold small mb-3">
                  <span className="flex-grow-1">{doc?.title}</span>
                  <i
                    className={doc.saved ? "fas fa-bookmark" : "far fa-bookmark"}
                    style={{ cursor: "pointer", marginRight: "10px", marginTop: "3px" }}
                    onClick={() => toggleBookmark(index)}
                  ></i>
                  <i
                    className="bi bi-fullscreen"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleFullscreenClick(doc)}
                  ></i>
                </div>
                <iframe
                  src={doc?.docs[0]}
                  width="100%"
                  height="90%"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    display: "block",
                  }}
                ></iframe>

                  {/* Resize Handles */}
                  <div
                  className="resize-handle"
                  onMouseDown={(e) => handleResize(e, index, "top-left")}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    cursor: "nwse-resize",
                    width: "10px",
                    height: "10px",
                    backgroundColor: "transparent",
                  }}
                ></div>
                <div
                  className="resize-handle"
                  onMouseDown={(e) => handleResize(e, index, "top-right")}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    cursor: "nesw-resize",
                    width: "10px",
                    height: "10px",
                    backgroundColor: "transparent",
                  }}
                ></div>
                <div
                  className="resize-handle"
                  onMouseDown={(e) => handleResize(e, index, "bottom-left")}
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    cursor: "nesw-resize",
                    width: "10px",
                    height: "10px",
                    backgroundColor: "transparent",
                  }}
                ></div>
                <div
                  className="resize-handle"
                  onMouseDown={(e) => handleResize(e, index, "bottom-right")}
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    cursor: "nwse-resize",
                    width: "10px",
                    height: "10px",
                    backgroundColor: "transparent",
                  }}
                ></div>
              </CardBody>
            </Card>
          </div>
        ))
      ) : (
        <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ minHeight: "100vh", textAlign: "center" }}
        >
        <AiOutlineFileSearch size={50} color="#ccc" />
        <h2>No Liked Documents Found</h2>
        </div>


      )}
      <div ref={lastDocumentElementRef} />
    </div>

    <Modal
      show={showModal}
      onHide={handleCloseModal}
      size="lg"
      centered
      className="fullscreen-modal"
    >
      <Modal.Body style={{ padding: 0 }}>
        <div style={{ position: "relative", height: "100%" }}>
          <div
            style={{
              padding: "10px",
              background: "rgba(255, 255, 255, 0.1)",
              backdropFilter: "blur(10px)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
            }}
          >
            <h5 style={{ margin: 0, color: "white" }}>{modalTitle}</h5>
            <i
              className="bi bi-fullscreen-exit"
              style={{ fontSize: "24px", cursor: "pointer", color: "white" }}
              onClick={handleCloseModal}
            ></i>
          </div>
          <iframe
            src={modalIframeSrc}
            title="Fullscreen Document"
            width="100%"
            height="calc(100% - 40px)"
            style={{ border: "none", minHeight: "90vh" }}
          ></iframe>
        </div>
      </Modal.Body>
    </Modal></>}
     

      <ToastContainer />
    </div>
  );
}

export default UserDashboard;
