import React from 'react';
import App from './../app.jsx';
import { Navigate } from 'react-router-dom';
import Dashboard from './../pages/dashboard/dashboard.js';
import UserDashboard from './../pages/dashboard/UserDashboard.js';
import Analytics from './../pages/analytics/analytics.js';
import EmailInbox from './../pages/email/inbox.js';
import EmailCompose from './../pages/email/compose.js';
import EmailDetail from './../pages/email/detail.js';
import Widgets from './../pages/widgets/widgets.js';
import PosCustomerOrder from './../pages/pos/customer-order.js';
import PosKitchenOrder from './../pages/pos/kitchen-order.js';
import PosCounterCheckout from './../pages/pos/counter-checkout.js';
import PosTableBooking from './../pages/pos/table-booking.js';
import PosMenuStock from './../pages/pos/menu-stock.js';
import UiBootstrap from './../pages/ui/bootstrap.js';
import UiButtons from './../pages/ui/buttons.js';
import UiCard from './../pages/ui/card.js';
import UiIcons from './../pages/ui/icons.js';
import UiModalNotifications from './../pages/ui/modal-notifications.js';
import UiTypography from './../pages/ui/typography.js';
import UiTabsAccordions from './../pages/ui/tabs-accordions.js';
import FormElements from './../pages/form/elements.js';
import FormPlugins from './../pages/form/plugins.js';
import FormWizards from './../pages/form/wizards.js';
import TableElements from './../pages/table/elements.js';
import TablePlugins from './../pages/table/plugins.js';
import ChartJs from './../pages/charts/js.js';
import ChartApex from './../pages/charts/apex.js';
import Map from './../pages/map/map.js';
import LayoutStarter from './../pages/layout/starter.js';
import LayoutFixedFooter from './../pages/layout/fixed-footer.js';
import LayoutFullHeight from './../pages/layout/full-height.js';
import LayoutFullWidth from './../pages/layout/full-width.js';
import LayoutBoxedLayout from './../pages/layout/boxed-layout.js';
import LayoutCollapsedSidebar from './../pages/layout/collapsed-sidebar.js';
import LayoutTopNav from './../pages/layout/top-nav.js';
import LayoutMixedNav from './../pages/layout/mixed-nav.js';
import LayoutMixedNavBoxedLayout from './../pages/layout/mixed-nav-boxed-layout.js';
import PagesScrumBoard from './../pages/pages/scrum-board.js';
import PagesProducts from './../pages/pages/products.js';
import PagesProductDetails from './../pages/pages/product-details.js';
import PagesOrders from './../pages/pages/orders.js';
import PagesOrderDetails from './../pages/pages/order-details.js';
import PagesGallery from './../pages/pages/gallery.js';
import PagesSearchResults from './../pages/pages/search-results.js';
import PagesComingSoon from './../pages/pages/coming-soon.js';
import PagesError from './../pages/pages/error.js';
import PagesLogin from './../pages/pages/login.js';
import PagesRegister from './../pages/pages/register.js';
import PagesMessenger from './../pages/pages/messenger.js';
import PagesDataManagement from './../pages/pages/data-management.js';
import PagesFileManager from './../pages/pages/file-manager.js';
import PagesPricing from './../pages/pages/pricing.js';
import AddSubscription from './../pages/pages/addSubscription.js';
import AdminDashboard from './../pages/pages/adminDashboard.js';
import MenuPermissions from './../pages/pages/menuPermissions.js';
import  ManageUsers from '../pages/pages/ManageUsers/manageUsers.js';
import  SetPassword from '../pages/pages/setPassword.js';
import BlurPage from '../pages/pages/BlurPage/blurPage.js';
import Landing from './../pages/landing/landing.js';
import Profile from './../pages/profile/profile.js';
import Calendar from './../pages/calendar/calendar.js';
import Settings from './../pages/settings/settings.js';
import Helper from './../pages/helper/helper.js';
import Contactus from './../pages/pages/coming-soon.js'
import ManageSubAdmins from './../pages/pages/subAdmin.js'
import AddDoc from './../pages/pages/addDoc.js'
import QcrNews from './../pages/qunatumComputingReport/news.js'
import QcrNewsArchive from './../pages/qunatumComputingReport/newsArchive.js'
import  QcrAnalysis from './../pages/qunatumComputingReport/analysis.js'
import  QcrAnalysisArchive from './../pages/qunatumComputingReport/analysisArchive.js'
import  Qnalysis from './../pages/qunatumComputingReport/qnalysis.js'
import  QRE from './../pages/qunatumComputingReport/qre.js'
import  Resources from './../pages/qunatumComputingReport/resources.js'
import  Podcasts from './../pages/qunatumComputingReport/podcasts.js'
import  Companies from './../pages/trackerSummaryData/companies.js'
import  Startups from './../pages/trackerSummaryData/startups.js'
import  Universities from './../pages/trackerSummaryData/universities.js'
import  Government from './../pages/trackerSummaryData/government.js'
import  NonProfit from './../pages/trackerSummaryData/nonProfit.js'
import  Investors from './../pages/trackerSummaryData/investors.js'
import  TrackUsers from './../pages/trackerSummaryData/Users.js'
import  Deals from './../pages/trackerSummaryData/deals.js'
import  UseCases from './../pages/trackerSummaryData/useCases.js'
import  ExpInvestors from './../pages/ExplorerDetails/Investors.js'
import  ExpUsers from './../pages/ExplorerDetails/Users.js'
import  ExpDeals from './../pages/ExplorerDetails/deals.js'
import EmbeddedDoc from './../pages/pages/embeddedDoc.js'

import AnalyticsIframe from './../pages/pages/analytics.js'
import { useSelector } from 'react-redux';
import PrivateRoute from '../components/privateComponent/privateComponent.jsx'; 
import ExpUseCases from '../pages/ExplorerDetails/useCases.js';
import QubitImplementations from '../pages/ExplorerDetails/qubitImp.js';
import QubitCount from '../pages/ExplorerDetails/qubitcount.js';

import QubitQuality from '../pages/ExplorerDetails/qubitquality.js';
import QubitTechnology from '../pages/ExplorerDetails/qubittechnology.js';
import CloudPartners from '../pages/ExplorerDetails/cloudPartners.js';
import SoftwarePartners from '../pages/ExplorerDetails/softwarePartners.js';
import QpuDevelopment from '../pages/ExplorerDetails/qpuDevelopment.js';
import OnPremiseDeployment from '../pages/ExplorerDetails/onPremDep.js';
import WiresCabling from '../pages/ExplorerDetails/wiresCabling.js';
import PlaybookDeal from '../pages/playbooks/deals.js';
import PlaybookUseCases from '../pages/playbooks/useCases.js';
import TaxonomyBrowser from '../pages/playbooks/taxBrowser.js';
import SupplyChain from '../pages/playbooks/supplyChain.js';
import Ecosystem from '../pages/playbooks/ecosystem.js';
import Strategy from '../pages/playbooks/strategy.js';
import China from '../pages/playbooks/china.js';
import FocusReports from '../pages/Reports/focusReports.js';
import OutlookReports from '../pages/Reports/outlookReports.js';



const AppRoute = [
  {
    path: '*', 
    element: <App />,
    children: [	
    	{ path: '', element: <Navigate to='/dashboard' /> },
    	{ path: 'dashboard', element:<PrivateRoute element={UserDashboard} />  },
    	{ path: 'analytics', element: <Analytics /> },
    	{ path: 'email/*', 
    		children: [
					{ path: 'inbox', element: <EmailInbox /> },
					{ path: 'compose', element: <EmailCompose /> },
					{ path: 'detail', element: <EmailDetail /> }
				]
			},
    	{ path: 'widgets', element: <Widgets /> },
    	{ path: 'pos/*', 
    		children: [
					{ path: 'customer-order', element: <PosCustomerOrder /> },
					{ path: 'kitchen-order', element: <PosKitchenOrder /> },
					{ path: 'counter-checkout', element: <PosCounterCheckout /> },
					{ path: 'table-booking', element: <PosTableBooking /> },
					{ path: 'menu-stock', element: <PosMenuStock /> }
				]
			},
    	{ path: 'ui/*', 
    		children: [
					{ path: 'bootstrap', element: <UiBootstrap /> },
					{ path: 'buttons', element: <UiButtons /> },
					{ path: 'card', element: <UiCard /> },
					{ path: 'icons', element: <UiIcons /> },
					{ path: 'modal-notifications', element: <UiModalNotifications /> },
					{ path: 'typography', element: <UiTypography /> },
					{ path: 'tabs-accordions', element: <UiTabsAccordions /> },
				]
			},
    	{ path: 'form/*', 
    		children: [
					{ path: 'elements', element: <FormElements /> },
					{ path: 'plugins', element: <FormPlugins /> },
					{ path: 'wizards', element: <FormWizards /> }
				]
			},
    	{ path: 'table/*', 
    		children: [
					{ path: 'elements', element: <TableElements /> },
					{ path: 'plugins', element: <TablePlugins /> }
				]
			},
    	{ path: 'chart/*', 
    		children: [
					{ path: 'chart-js', element: <ChartJs /> },
					{ path: 'chart-apex', element: <ChartApex /> }
				]
			},
    	{ path: 'layout/*', 
    		children: [
					{ path: 'starter-page', element: <LayoutStarter /> },
					{ path: 'fixed-footer', element: <LayoutFixedFooter /> },
					{ path: 'full-height', element: <LayoutFullHeight /> },
					{ path: 'full-width', element: <LayoutFullWidth /> },
					{ path: 'boxed-layout', element: <LayoutBoxedLayout /> },
					{ path: 'collapsed-sidebar', element: <LayoutCollapsedSidebar /> },
					{ path: 'top-nav', element: <LayoutTopNav /> },
					{ path: 'mixed-nav', element: <LayoutMixedNav /> },
					{ path: 'mixed-nav-boxed-layout', element: <LayoutMixedNavBoxedLayout /> }
				]
			},
    	{ path: 'pages/*', 
    		children: [
					{ path: 'scrum-board', element: <PagesScrumBoard /> },
					{ path: 'products', element: <PagesProducts /> },
					{ path: 'product-details', element: <PagesProductDetails /> },
					{ path: 'orders', element: <PagesOrders /> },
					{ path: 'order-details', element: <PagesOrderDetails /> },
					{ path: 'gallery', element: <PagesGallery /> },
					{ path: 'search-results', element: <PagesSearchResults /> },
					{ path: 'coming-soon', element: <PagesComingSoon /> },
					{path :"contact-us", element: <Contactus />},
					{ path: 'setPassword', element: <SetPassword /> },
					{ path: 'error', element: <PagesError /> },
					{ path: 'login', element: <PagesLogin /> },
					{ path: 'register', element: <PagesRegister /> },
					{ path: 'messenger', element: <PagesMessenger /> },
					{ path: 'news', element: <PagesDataManagement /> },
					{ path: 'file-manager', element: <PagesFileManager /> },
					{ path: 'pricing', element: <PagesPricing /> },
					{ path: 'adminDashboard', element:<PrivateRoute element={AdminDashboard} /> },
					{ path: 'subscriptionAdd', element: <PrivateRoute element={AddSubscription} /> },
					{ path: 'assignMenuPermission', element:<PrivateRoute element={MenuPermissions} /> },
					{ path: 'premiumAccess', element:<PrivateRoute element={BlurPage} /> },
					{ path: 'client', element:<PrivateRoute element={ManageUsers} />},
					{ path: 'subAdmin', element:<PrivateRoute element={ManageSubAdmins} />},
					{ path: 'addDocument', element:<PrivateRoute element={AddDoc} />},
					{ path: 'embeddedData', element:<PrivateRoute element={EmbeddedDoc} />},
					{ path: 'analytics', element:<PrivateRoute element={AnalyticsIframe} />},

				]
			},
    	{ path: 'map', element: <Map /> },
    	{ path: 'landing', element: <Landing /> },
    	{ path: 'profile', element: <Profile /> },
    	{ path: 'calendar', element: <Calendar /> },
    	{ path: 'settings', element: <Settings /> },
    	{ path: 'helper', element: <Helper /> },
		{ path: 'quantum-computing-report/news', element: <QcrNews /> },
		{ path: 'quantum-computing-report/news-archive', element: <QcrNewsArchive /> },
		{ path: 'quantum-computing-report/analysis', element: <QcrAnalysis /> },
		{ path: 'quantum-computing-report/analysis-archive', element: <QcrAnalysisArchive /> },
		{ path: 'quantum-computing-report/qnalysis', element: <Qnalysis /> },
		{ path: 'quantum-Computing-report/resources', element: <Resources /> },
		{ path: 'quantum-Computing-report/podcasts', element: <Podcasts /> },
		{ path: 'quantum-Computing-report/qre', element: <QRE /> },
		{ path: 'trackers-summary-data/companies', element: <Companies /> },
		{ path: 'trackers-summary-data/startups', element: <Startups /> },
		{ path: 'trackers-summary-data/universities', element: <Universities /> },
		{ path: 'trackers-summary-data/government', element: <Government /> },
		{ path: 'trackers-summary-data/non-profit', element: <NonProfit /> },
		{ path: 'trackers-summary-data/investors', element: <Investors /> },
		{ path: 'trackers-summary-data/users', element: <TrackUsers /> },
		{ path: 'trackers-summary-data/deals', element: <Deals /> },
		{ path: 'trackers-summary-data/use-cases', element: <UseCases /> },
		{ path: 'explorers-detailed-data-tools/investors', element: <ExpInvestors /> },
		{ path: 'explorers-detailed-data-tools/users', element: <ExpUsers /> },
		{ path: 'explorers-detailed-data-tools/deals', element: <ExpDeals /> },
		{ path: 'explorers-detailed-data-tools/use-cases', element: <ExpUseCases /> },
		{ path: 'explorers-detailed-data-tools/qubit-implementations', element: <QubitImplementations /> },
		{ path: 'explorers-detailed-data-tools/qubit-count', element: < QubitCount/> },
		{ path: 'explorers-detailed-data-tools/qubit-quality', element: < QubitQuality/> },
		{ path: 'explorers-detailed-data-tools/qubit-technology', element: < QubitTechnology/> },
		{ path: 'explorers-detailed-data-tools/cloud-partners', element: < CloudPartners/> },
		{ path: 'explorers-detailed-data-tools/software-partners', element: < SoftwarePartners/> },
		{ path: 'explorers-detailed-data-tools/qpu-development', element: < QpuDevelopment/> },
		{ path: 'explorers-detailed-data-tools/on-premise-deployments', element: < OnPremiseDeployment/> },
		{ path: 'explorers-detailed-data-tools/wires-and-cabling', element: < WiresCabling/> },
		{ path: 'playbooks-advanced-intelligence-analytics/deals', element: < PlaybookDeal/> },
		{ path: 'playbooks-advanced-intelligence-analytics/use-cases', element: < PlaybookUseCases/> },
		{ path: 'playbooks-advanced-intelligence-analytics/taxonomy-browser', element: < TaxonomyBrowser/> },
		{ path: 'playbooks-advanced-intelligence-analytics/supply-chain', element: < SupplyChain/> },
		{ path: 'playbooks-advanced-intelligence-analytics/ecosystem', element: < Ecosystem/> },
		{ path: 'playbooks-advanced-intelligence-analytics/strategy', element: < Strategy/> },
		{ path: 'playbooks-advanced-intelligence-analytics/china', element: < China/> },
		{ path: 'reports/focus-reports', element: < FocusReports/> },
		{ path: 'reports/outlook-reports', element: < OutlookReports/> },


    	{ path: '*', element: <PagesError /> },
		
		]
  }
];


export default AppRoute;