import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import UserTable from "../../components/userData/table.js";
import EmbeddedDoc from "../../components/userData/getDocs.js"; // Import EmbeddedDoc

function QcrNews() {
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [mainCategoryId, setMainCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null); // Add state for the selected document

  const columns = ["Title", "Created By", "Tags", "Description"];

  // Get user permissions from Redux state
  const userPermissions = useSelector((state) => state.getUser.user?.Subscription?.Permissions || []);
  const token = sessionStorage.getItem("token");

  // Function to find the main and subcategory IDs based on user permissions
  const findCategoryIds = () => {
    const mainCategory = userPermissions.find((permission) =>permission.name.includes("Quantum Computing Report") && permission.parentId === null);

    if (mainCategory) {
      setMainCategoryId(mainCategory.id);

      const subCategory = userPermissions.find((permission) =>permission.name== "News" && permission.parentId === mainCategory.id);

      if (subCategory) {
        setSubCategoryId(subCategory.id);
      }
    }
  };

  useEffect(() => {
    // Call the function to get mainCategoryId and subCategoryId when userPermissions change
    findCategoryIds();
  }, [userPermissions]);

  useEffect(() => {
    // Fetch documents only when mainCategoryId and subCategoryId are available
    if (mainCategoryId && subCategoryId) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/getDocInfobyId`, {
          params: {
            mainCategoryId: mainCategoryId,
            subCategoryId: subCategoryId,
            pageSize: 10,
            page: currentPage,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setDocuments(response.data.documents);
          setTotalPages(response.data.totalPages);
        })
        .catch((error) => {
          toast.error("No Documents Found");
        });
    }
  }, [mainCategoryId, subCategoryId, currentPage, token]);

  const handleAddShow = () => {
    // Add show logic here
  };

  const handleEditShow = (doc) => {
    // Edit show logic here
  };

  const handleDeleteShow = (doc) => {
    // Delete show logic here
  };

  const handleDocsShow = (doc) => {
    // Document show logic here
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowClick = (doc) => {
    setSelectedDoc(doc); // Set the clicked document as selected
  };

  const handleBack = () => {
    setSelectedDoc(null); // Clear selectedDoc to show the table again
  };

  return (
    <div>
      {/* Conditionally render the UserTable if no document is selected */}
      {!selectedDoc && (
        <UserTable
          data={documents}
          columns={columns}
          pageHeading="News"
          handleAddShow={handleAddShow}
          handleEditShow={handleEditShow}
          handleDeleteShow={handleDeleteShow}
          handleDocsShow={handleDocsShow}
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          handleRowClick={handleRowClick} // Pass the row click handler to the UserTable
        />
      )}

      {/* Conditionally render the EmbeddedDoc component if a document is selected */}
      {selectedDoc && (
        <div style={{ marginTop: "20px" }}>
         
          <EmbeddedDoc selectedDoc={selectedDoc} handleBack={handleBack} /> {/* Pass the selected document and back handler */}
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default QcrNews;
