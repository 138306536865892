import React from "react";
import Sidebar from "../../components/sidebar/sidebar.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaFileAlt } from "react-icons/fa"; // Import a document icon from react-icons

function EmbeddedDoc({ selectedDoc, handleBack }) {
  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  // Function to extract data after the first underscore after the last slash
  const extractDocName = (docUrl) => {
    const lastSlashIndex = docUrl.lastIndexOf("/"); // Find the last slash
    const fileNameWithUnderscore = docUrl.substring(lastSlashIndex + 1); // Get the part after the last slash
    const underscoreIndex = fileNameWithUnderscore.indexOf("_"); // Find the first underscore

    if (underscoreIndex !== -1) {
      return fileNameWithUnderscore.substring(underscoreIndex + 1); // Get the part after the underscore
    }

    return fileNameWithUnderscore; // Return the whole string if no underscore found
  };

  return (
    <div className="d-flex">
     
      <div className="content p-4" style={{ flex: 1 }}>
        {/* Back Button */}
        <button className="btn btn-secondary mb-3" onClick={handleBack}>
          Back to Table
        </button>

        <h1>Analytics for {selectedDoc.title}</h1>
        <p>{selectedDoc.description}</p>

        {/* If type is 'embedded', show the iframe */}
        {selectedDoc.type === "embedded" ? (
          <div>
            <iframe
              src={selectedDoc.docs[0]} // Use the first document URL from the selectedDoc
              width="100%"
              height="500"
              frameBorder="0"
              allow="fullscreen"
            ></iframe>

            {/* Full Screen Button */}
            <button
              className="btn btn-primary mt-3"
              onClick={() => openInNewTab(selectedDoc.docs[0])}
            >
              Full Screen
            </button>
          </div>
        ) : (
          // If type is not 'embedded', show a list of documents with their names and icons
          <div>
            <h3>Available Documents</h3>
            <div className="d-flex flex-wrap">
              {selectedDoc.docs.map((docUrl, index) => (
                <div
                  key={index}
                  className="doc-item card-custom m-2 p-3 text-center"
                  style={{
                    width: "400px",
                    textAlign: "center",
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    overflow: "hidden",
                    backgroundColor: "#f8f9fa",
                  }}
                >
                  {/* Document Icon */}
                  <FaFileAlt size={50} style={{ marginBottom: "10px", color: "#6c757d" }} />

                  {/* Extracted Document Name */}
                  <h5 style={{ color: "black" }}>{extractDocName(docUrl)}</h5>

                  {/* Full Screen Button for individual document */}
                  <button
                    className="btn btn-primary mt-2"
                    onClick={() => openInNewTab(docUrl)}
                  >
                    Open Document
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EmbeddedDoc;
