import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { AppSettings } from './../../config/app-settings.js';
import { Card, CardBody } from './../../components/card/card.jsx';

function SetPassword() {
  const navigate = useNavigate();
  const context = useContext(AppSettings);

  // State to store user info and subscription details
  const [userInfo, setUserInfo] = useState({
    email: '',
    name: '',
    title: '',
    company: '',
    country: '',
    subscriptionId: '',
    token: '',
    userType:''
  });

  // States for passwords
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [apiError, setApiError] = useState('');

  // State for subscriptions
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState('');

  // State for token validation
  const [isTokenExpired, setIsTokenExpired] = useState(false);


  useEffect(() => {
    // Extract query parameters from URL
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get('email');
    const name = searchParams.get('name');
    const title = searchParams.get('title');
    const company = searchParams.get('company');
    const country = searchParams.get('country');
    const subscriptionId = searchParams.get('subscriptionId');
    const token = searchParams.get('token');
    const userType = searchParams.get('userType');

    // Set extracted values in state
    setUserInfo({ email, name, title, company, country, subscriptionId, token,userType });

    // Set initial value for selected subscription
    setSelectedSubscription(subscriptionId || '');

    // Check token validity
    checkTokenValidity(token);

    context.setAppHeaderNone(true);
    context.setAppSidebarNone(true);
    context.setAppContentClass('p-0');

    return function cleanUp() {
      context.setAppHeaderNone(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass('');
    };
  }, []);

  // Function to check token validity
  const checkTokenValidity = async (token) => {
    try {
     
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/checkExpirySetUser`, { token });

      if (response.data.expired) {
        setIsTokenExpired(true);
      } else {
        fetchSubscriptions();
      }
    } catch (error) {
      setApiError('An error occurred while checking token validity.');
    } 
  };

  // Function to fetch subscriptions from API
  const fetchSubscriptions = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getSubscriptions`);
      if (response.status === 200) {
        setSubscriptions(response.data);
      } else {
        setApiError('Failed to fetch subscriptions. Please try again.');
      }
    } catch (error) {
      setApiError('An error occurred while fetching subscriptions.');
    }
  };

  // Validate passwords
  const validatePasswords = () => {
    let isValid = true;

    if (!password) {
      setPasswordError('Password is required.');
      isValid = false;
    }

    if (!confirmPassword) {
      setConfirmPasswordError('Confirm Password is required.');
      isValid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      isValid = false;
    }

    // Clear errors after 2 seconds
    if (!isValid) clearErrors();
    return isValid;
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validatePasswords()) {
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/setPassword`, {
        token: userInfo.token,
        password,
        userType:userInfo.userType
      });

      if (response.status === 200) {
        toast.success('Password set successfully!');
        navigate('/pages/login'); // Redirect to login page
      } else {
        setApiError('Failed to set password. Please try again.');
      }
    } catch (error) {
      setApiError('An error occurred. Please try again.');
    }
  };

  // Clear errors after 2 seconds
  const clearErrors = () => {
    setTimeout(() => {
      setApiError('');
      setPasswordError('');
      setConfirmPasswordError('');
    }, 2000);
  };



  if (isTokenExpired) {
    return (
    	<div className="error-page">
             <div className="error-page-content">
    
      <h2> Session has expired. Please request a new link.</h2> 
				
   
  </div>
        </div>
     
    );
  }

  return (
    <div className="register">
      <div className="brand">
        <ToastContainer />
      </div>
      <div className="register-content">
        <form onSubmit={handleSubmit}>
          <h1 className="text-center">Set Your Password</h1>

          {/* Display API error at the top */}
          {apiError && <div className="alert alert-danger">{apiError}</div>}

          {/* Read-only fields to display user info */}
          <div className="mb-3">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              value={userInfo.name}
              readOnly
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Email</label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              value={userInfo.email}
              readOnly
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Title</label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              value={userInfo.title}
              readOnly
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Company</label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              value={userInfo.company}
              readOnly
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Country</label>
            <input
              type="text"
              className="form-control form-control-lg bg-white bg-opacity-5"
              value={userInfo.country}
              readOnly
            />
          </div>

          {/* Subscription selection field */}
          <div className="mb-3">
            <label className="form-label">Subscription</label>
            <select
              className="form-select form-select-lg bg-white bg-opacity-5"
              value={selectedSubscription}
              onChange={(e) => setSelectedSubscription(e.target.value)}
              disabled={!!userInfo.subscriptionId} // Disable if subscriptionId exists
            >
              <option value="">Select Subscription</option>
              {subscriptions.map((subscription) => (
                <option key={subscription.id} value={subscription.id}>
                  {subscription.name}
                </option>
              ))}
            </select>
          </div>

          {/* Password fields */}
          <div className="mb-3">
            <label className="form-label">New Password</label>
            <input
              type="password"
              className="form-control form-control-lg bg-white bg-opacity-5"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError('');
              }}
            />
            {passwordError && <div className="text-danger">{passwordError}</div>}
          </div>
          <div className="mb-3">
            <label className="form-label">Confirm New Password</label>
            <input
              type="password"
              className="form-control form-control-lg bg-white bg-opacity-5"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setConfirmPasswordError(password !== e.target.value ? 'Passwords do not match.' : '');
              }}
            />
            {confirmPasswordError && <div className="text-danger">{confirmPasswordError}</div>}
          </div>

          <div className="mb-3">
            <button type="submit" className="btn btn-outline-theme btn-lg d-block w-100">Set Password</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SetPassword;
