import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Card, CardBody } from "./../../components/card/card.jsx";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Modal,Spinner } from "react-bootstrap";
import UserTable from "./../../components/userData/table.js";
import EmbeddedDoc from "./../../components/userData/getDocs.js"; // Import EmbeddedDoc

let zIndexCounter = 1000; // Global z-index counter

function QubitCount() {
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [mainCategoryId, setMainCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalIframeSrc, setModalIframeSrc] = useState("");
  const [activeTab, setActiveTab] = useState("embedded");
  const [draggedCardIndex, setDraggedCardIndex] = useState(null);
  const [cardZIndices, setCardZIndices] = useState({});
  const [cardSizes, setCardSizes] = useState({}); // Store width and height per card
  const columns = ["Title", "Created By", "Tags", "Description"];
  const [selectedDoc, setSelectedDoc] = useState(null); // Add state for the selected document

  const userPermissions = useSelector(
    (state) => state.getUser.user?.Subscription?.Permissions || []
  );
  const token = sessionStorage.getItem("token");

  const containerRef = useRef(null);
  const observerRef = useRef();

  const toggleBookmark = async (index) => {
    const document = documents[index]; // Get the selected document
    const { id: documentId } = document; // Assuming document has an 'id' field
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/bookmarkDocument`,
        { documentId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      // Update the state to reflect the saved status
      const updatedDocuments = [...documents];
      updatedDocuments[index] = {
        ...document,
        saved: response.data.saved, // Update the saved status from the API response
      };
  
      setDocuments(updatedDocuments); // Update the documents state
    } catch (error) {
      console.error("Error bookmarking document:", error);
    }
  };
  

  const findCategoryIds = () => {
    const mainCategory = userPermissions.find(
      (permission) =>
        permission.name.includes("Explorers | Detailed data & tools") &&
        permission.parentId === null
    );

    if (mainCategory) {
      setMainCategoryId(mainCategory.id);

      const subCategory = userPermissions.find(
        (permission) =>
          permission.name.includes("Qubit count") &&
          permission.parentId === mainCategory.id
      );

      if (subCategory) {
        setSubCategoryId(subCategory.id);
      }
    }
  };

  useEffect(() => {
    findCategoryIds();
  }, [userPermissions]);

  useEffect(() => {
    if (mainCategoryId && subCategoryId) {
      fetchDocuments(currentPage);
    }
  }, [mainCategoryId, subCategoryId, currentPage, token]);

  const fetchDocuments = (page) => {
    setLoading(true); // Show loading spinner
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/getDocInfobyId`, {
        params: {
          mainCategoryId,
          subCategoryId,
          pageSize: 10,
          page,
        },
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setDocuments((prevDocs) => [...prevDocs, ...response.data.documents]);
        setTotalPages(response.data.totalPages);
      })
      .catch(() => {
        toast.error("No Documents Found");
      })
      .finally(() => {
        setLoading(false); // Hide loading spinner
      });
  };

  const lastDocumentElementRef = (node) => {
    if (loading) return;
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    });
    if (node) observerRef.current.observe(node);
  };

  const handleFullscreenClick = (doc) => {
    setModalIframeSrc(doc?.docs[0]);
    setModalTitle(doc.title)
    setShowModal(true);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleBack = () => {
    setSelectedDoc(null); // Clear selectedDoc to show the table again
  };

  const handleRowClick = (doc) => {
    setSelectedDoc(doc); // Set the clicked document as selected
  };

  const handleDragStart = (e, index) => {
    if (e.target.className.includes("resize-handle")) return;

    e.preventDefault();
    setDraggedCardIndex(index);

    const cardElement = e.currentTarget;
    const iframe = cardElement.querySelector("iframe");

    iframe.style.pointerEvents = "none"; // Temporarily disable iframe interaction

    const { left, top } = cardElement.getBoundingClientRect();
    const offsetX = e.clientX - left;
    const offsetY = e.clientY - top;

    setCardZIndices((prev) => ({
      ...prev,
      [index]: ++zIndexCounter, // Increment global z-index
    }));

    const handleMouseMove = (moveEvent) => {
      const containerBounds = containerRef.current.getBoundingClientRect();
      let newLeft = moveEvent.clientX - offsetX;
      let newTop = moveEvent.clientY - offsetY;

      // Constrain within container
      newLeft = Math.max(0, Math.min(newLeft, containerBounds.width - cardElement.offsetWidth));
      newTop = Math.max(0, Math.min(newTop, containerBounds.height - cardElement.offsetHeight));

      // Clear the space for the dragged card
      const draggedCardZIndex = cardZIndices[index] || 1;

      const allCards = Array.from(containerRef.current.children);
      allCards.forEach((card, i) => {
        if (i !== index) {
          const { left: cardLeft, top: cardTop } = card.getBoundingClientRect();
          const cardWidth = card.offsetWidth;
          const cardHeight = card.offsetHeight;

          // Check for overlap with other cards
          if (
            newLeft < cardLeft + cardWidth &&
            newLeft + cardElement.offsetWidth > cardLeft &&
            newTop < cardTop + cardHeight &&
            newTop + cardElement.offsetHeight > cardTop
          ) {
            // Prevent overlapping by moving other cards out of the way
            if (draggedCardZIndex < (cardZIndices[i] || 1)) {
              card.style.transform = "translateY(-100px)"; // Move the overlapping card up
            }
          }
        }
      });

      // Update the position of the dragged card
      cardElement.style.position = "absolute";
      cardElement.style.left = `${newLeft}px`;
      cardElement.style.top = `${newTop}px`;
    };

    const handleMouseUp = () => {
      iframe.style.pointerEvents = "auto"; // Re-enable iframe interaction
      setDraggedCardIndex(null);

      // Reset the transform of all cards
      const allCards = Array.from(containerRef.current.children);
      allCards.forEach((card) => {
        card.style.transform = "translateY(0)";
      });

      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const handleResize = (e, index, direction) => {
    e.preventDefault();

    const cardElement = e.currentTarget.parentElement;
    const initialWidth = cardElement.offsetWidth;
    const initialHeight = cardElement.offsetHeight;
    const startX = e.clientX;
    const startY = e.clientY;

    const handleMouseMove = (moveEvent) => {
      const deltaX = moveEvent.clientX - startX;
      const deltaY = moveEvent.clientY - startY;

      let newWidth = initialWidth + (direction.includes("right") ? deltaX : -deltaX);
      let newHeight = initialHeight + (direction.includes("bottom") ? deltaY : -deltaY);

      // Minimum width and height constraints
      newWidth = Math.max(400, newWidth); // Minimum width of 400px
      newHeight = Math.max(400, newHeight); // Minimum height of 400px

      // Set new size only if it doesn't exceed the container's dimensions
      const containerBounds = containerRef.current.getBoundingClientRect();
      if (
        newWidth <= containerBounds.width && 
        newHeight <= containerBounds.height
      ) {
        setCardSizes((prev) => ({
          ...prev,
          [index]: { width: newWidth, height: newHeight },
        }));
      }
    };

    const handleMouseUp = () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        minHeight: "100vh",
        overflow: "auto", // Allow scrolling if content exceeds the viewport
      }}
    >
       {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
          <Spinner animation="border" variant="primary" /> {/* Loader */}
        </div>
      ):(<>
        <div className="tab-bar" style={{ marginBottom: "20px" }}>
        <button
          className={`btn btn-outline-theme ${activeTab === "embedded" ? "active" : ""}`}
          onClick={() => setActiveTab("embedded")}
          style={{ marginRight: "20px" }}
        >
          Embedded Data
        </button>
        <button
          className={`btn btn-outline-theme ${activeTab === "documents" ? "active" : ""}`}
          onClick={() => setActiveTab("documents")}
        >
          Documents
        </button>
      </div>
      {activeTab=="embedded"?  <div className="row" style={{ position: "relative" }}>
        {documents.length > 0 ? (
          documents.map((doc, index) => (
            <div
              className="col-xl-6 col-lg-6 col-md-12 mb-4"
              key={index}
              onMouseDown={(e) => handleDragStart(e, index)}
              style={{ position: "relative" }} // Ensure relative positioning for resizing
            >
              <Card
                className="card mb-3"
                style={{
                  position: "relative",
                  zIndex: cardZIndices[index] || 1,
                  width: cardSizes[index]?.width || "480px", // Default width
                  height: cardSizes[index]?.height || "480px", // Default height
                }}
              >
                <CardBody style={{ position: "relative", height: "100%" }}>
                  <div className="d-flex fw-bold small mb-3">
                    <span className="flex-grow-1">{doc?.title}</span>

                    <i
    className={doc.saved ? "fas fa-bookmark" : "far fa-bookmark"}
    style={{ cursor: "pointer", marginRight: "10px",marginTop: "3px" }}
     onClick={() => toggleBookmark(index)}
  ></i>
                    <i
                      className="bi bi-fullscreen"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleFullscreenClick(doc)}
                    ></i>
                  </div>
                  <iframe
                    src={doc?.docs[0]}
                    width="100%"
                    height="90%" // Set height to 90% of the card body
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      transition: "height 0.2s ease", // Smooth transition for height changes
                      display: "block", // Ensure the iframe behaves as a block element
                    }}
                  ></iframe>

                  {/* Resize Handles */}
                  <div
                    className="resize-handle"
                    onMouseDown={(e) => handleResize(e, index, "top-left")}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      cursor: "nwse-resize",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "transparent",
                    }}
                  ></div>
                  <div
                    className="resize-handle"
                    onMouseDown={(e) => handleResize(e, index, "top-right")}
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      cursor: "nesw-resize",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "transparent",
                    }}
                  ></div>
                  <div
                    className="resize-handle"
                    onMouseDown={(e) => handleResize(e, index, "bottom-left")}
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      cursor: "nesw-resize",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "transparent",
                    }}
                  ></div>
                  <div
                    className="resize-handle"
                    onMouseDown={(e) => handleResize(e, index, "bottom-right")}
                    style={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      cursor: "nwse-resize",
                      width: "10px",
                      height: "10px",
                      backgroundColor: "transparent",
                    }}
                  ></div>
                </CardBody>
              </Card>
            </div>
          ))
        ) : (
          <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ minHeight: "100vh", textAlign: "center" }}
        >
          <AiOutlineFileSearch size={50} color="#ccc" />
          <h2>No Documents Found</h2>
        </div>
        )}
        <div ref={lastDocumentElementRef} />
      </div>:(  <div>
      {/* Conditionally render the UserTable if no document is selected */}
      {!selectedDoc && (
        <UserTable
          data={documents}
          columns={columns}
          pageHeading="Analysis Archive"
         
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          handleRowClick={handleRowClick} // Pass the row click handler to the UserTable
        />
      )}

      {/* Conditionally render the EmbeddedDoc component if a document is selected */}
      {selectedDoc && (
        <div style={{ marginTop: "20px" }}>
         
          <EmbeddedDoc selectedDoc={selectedDoc} handleBack={handleBack} /> {/* Pass the selected document and back handler */}
        </div>
      )}

      <ToastContainer />
    </div>)
    }

    

      <Modal
    show={showModal}
    onHide={handleCloseModal}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="fullscreen-modal"
>
    <Modal.Body style={{ padding: '0' }}>
        <div style={{ position: "relative", height: "100%" }}>
            {/* Modal Title with Transparent Blur Background */}
            <div style={{
                padding: '10px',
                background: 'rgba(255, 255, 255, 0.1)', // Semi-transparent background
                backdropFilter: 'blur(10px)', // Blur effect
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid rgba(255, 255, 255, 0.2)' // Optional border
            }}>
                <h5 style={{ margin: 0, color: 'white' }}>{modalTitle}</h5>
                <i
                    className="bi bi-fullscreen-exit"
                    style={{
                        fontSize: "24px",
                        cursor: "pointer",
                        color: 'white' // White color for the close icon
                    }}
                    onClick={handleCloseModal}
                ></i>
            </div>
            <iframe
                src={modalIframeSrc}
                title="Fullscreen Document"
                width="100%"
                height="calc(100% - 40px)" // Adjust height to fill the remaining space
                style={{ border: "none", minHeight: "90vh" }}
            ></iframe>
        </div>
    </Modal.Body>
</Modal>
      </>)}
    
    

      <ToastContainer />
    </div>
  );
}

export default QubitCount;
